<template>
  <div class="popup_wrap" style="width:1000px;">
    <button class="layer_close" @click.prevent="$emit('close')">close</button>
    <div class="popup_cont">
      <h1 class="page_title">{{ $t('cp.CTRL010P050.001') }}</h1>
      <div class="content_box mt10">
        <table class="tbl_search">
          <colgroup>
            <col width="80px">
            <col>
            <col width="90px">
            <col>
            <col width="90px">
            <col>
            <col width="100px">
          </colgroup>
          <tbody>
            <tr>
              <th>{{ $t('cp.CTRL010P050.002') }}</th>
              <td>
                <select v-model="param.cstCatCd" disabled>
                  <option v-for="(item, idx) in cstCatlist" :key="idx" :value="item.cstCatCd">{{ item.cstCatNm }}</option>
                </select>
              </td>
              <th>{{ $t('cp.CTRL010P050.003') }}</th>
              <td>
                <input type="text" v-model="param.cstNo" @keypress.enter="searchData">
              </td>
              <th>{{ $t('cp.CTRL010P050.004') }}</th>
              <td>
                <select v-model="param.ctrNm">
                  <option value="">{{ $t('cp.COMMON.002') }}</option>
                  <option v-for="(item, idx) in countryList" :key="idx" :value="item.ctrCd">{{ item.ctrEnm }}</option>
                </select>
              </td>
              <td class="text_right">
              </td>
            </tr>
            <tr>
              <th>{{ $t('cp.CTRL010P050.005') }}</th>
              <td>
                <input type="text" v-model="param.cstEnm" @keypress.enter="searchData">
              </td>
              <th>{{ $t('cp.CTRL010P050.006') }}</th>
              <td>
                <input type="text" v-model="param.cstNm" @keypress.enter="searchData">
              </td>
              <th>{{ $t('cp.CTRL010P050.007') }}</th>
              <td>
                <input type="text" v-model="param.salUno" @keypress.enter="searchData">
              </td>
              <td class="text_right">
                <a class="button blue sh" href="#" @click.prevent="searchData">{{ $t('cp.COMMON.001') }}</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="content_box mt10">
        <div id="realgrid" style="width: 100%; height: 250px" />
        <div class="text_center mt10">
          <button class="tbl_icon arrowdown" @click.prevent="add">arrowdown</button>
          <button class="tbl_icon arrowup ml20" @click.prevent="del">arrowup</button>
        </div>
        <div id="realgrid2" style="width: 100%; height: 250px; margin-top: 10px" />
      </div>
      <div class="mt10 text_center">
        <a class="button blue lg" href="#" @click.prevent="select">{{ $t('cp.COMMON.002') }}</a>
      </div>
    </div>
  </div>
</template>

<script>
import { GridView, LocalDataProvider } from 'realgrid'
import cpCommonInfo from '@/api/rest/cp/cpCommonInfo'
let gridView = GridView
let provider = LocalDataProvider
let gridView2 = GridView
let provider2 = LocalDataProvider

const fields = [
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'cstEnm', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstCatCd', dataType: 'text' },
  { fieldName: 'cstCatNm', dataType: 'text' },
  { fieldName: 'exSalUno', dataType: 'text' },
  { fieldName: 'imSalUno', dataType: 'text' },
  { fieldName: 'jbtSalUno', dataType: 'text' },
  { fieldName: 'ctrCd', dataType: 'text' },
  { fieldName: 'ctrNm', dataType: 'text' }
]
const columns = [
  { name: 'cstCatNm', fieldName: 'cstCatNm', header: { text: app.$t('cp.CTRL010P050.002') }, editable: false, width: 70 },
  { name: 'cstNo', fieldName: 'cstNo', header: { text: app.$t('cp.CTRL010P050.003') }, editable: false, width: 70 },
  { name: 'cstEnm', fieldName: 'cstEnm', header: { text: app.$t('cp.CTRL010P050.005') }, editable: false, width: 110, styleName: 'text_left' },
  { name: 'cstNm', fieldName: 'cstNm', header: { text: app.$t('cp.CTRL010P050.006') }, editable: false, width: 110, styleName: 'text_left' },
  { name: 'ctrNm', fieldName: 'ctrNm', header: { text: app.$t('cp.CTRL010P050.004') }, editable: false, width: 80, styleName: 'text_left' },
  { name: 'exSalUno', fieldName: 'exSalUno', header: { text: app.$t('cp.CTRL010P050.008') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'imSalUno', fieldName: 'imSalUno', header: { text: app.$t('cp.CTRL010P050.009') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'jbtSalUno', fieldName: 'jbtSalUno', header: { text: app.$t('cp.CTRL010P050.010') }, editable: false, width: 100, styleName: 'text_left' }
]

const fields2 = [
  { fieldName: 'cstNo', dataType: 'text' },
  { fieldName: 'cstEnm', dataType: 'text' },
  { fieldName: 'cstNm', dataType: 'text' },
  { fieldName: 'cstCatCd', dataType: 'text' },
  { fieldName: 'cstCatNm', dataType: 'text' },
  { fieldName: 'exSalUno', dataType: 'text' },
  { fieldName: 'imSalUno', dataType: 'text' },
  { fieldName: 'jbtSalUno', dataType: 'text' },
  { fieldName: 'ctrCd', dataType: 'text' },
  { fieldName: 'ctrNm', dataType: 'text' }
]
const columns2 = [
  { name: 'cstCatNm', fieldName: 'cstCatNm', header: { text: app.$t('cp.CTRL010P050.002') }, editable: false, width: 70 },
  { name: 'cstNo', fieldName: 'cstNo', header: { text: app.$t('cp.CTRL010P050.003') }, editable: false, width: 70 },
  { name: 'cstEnm', fieldName: 'cstEnm', header: { text: app.$t('cp.CTRL010P050.005') }, editable: false, width: 110, styleName: 'text_left' },
  { name: 'cstNm', fieldName: 'cstNm', header: { text: app.$t('cp.CTRL010P050.006') }, editable: false, width: 110, styleName: 'text_left' },
  { name: 'ctrNm', fieldName: 'ctrNm', header: { text: app.$t('cp.CTRL010P050.004') }, editable: false, width: 80, styleName: 'text_left' },
  { name: 'exSalUno', fieldName: 'exSalUno', header: { text: app.$t('cp.CTRL010P050.008') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'imSalUno', fieldName: 'imSalUno', header: { text: app.$t('cp.CTRL010P050.009') }, editable: false, width: 100, styleName: 'text_left' },
  { name: 'jbtSalUno', fieldName: 'jbtSalUno', header: { text: app.$t('cp.CTRL010P050.010') }, editable: false, width: 100, styleName: 'text_left' }
]

export default {
  name: 'CstSearchListPop',
  props: {
    parentInfo: {
      type: Object,
      default: function () {
        return {
          position: '',
          ships: []
        }
      }
    }
  },
  data: function () {
    return {
      param: {
        cstCatCd: '',
        cstNo: '',
        cstEnm: '',
        cstNm: '',
        ctrNm: '',
        salUno: '',
        ships: []
      },
      cstCatlist: [],
      list: [],
      cstChooseList: [],
      countryList: []
    }
  },
  mounted () {
    const $vm = this
    provider = new LocalDataProvider(true)
    gridView = new GridView('realgrid')
    provider.setFields(fields)
    gridView.setColumns(columns)
    gridView.setDataSource(provider)

    // 그리고 헤더 높이 조정
    gridView.header.heights = [30]
    gridView.displayOptions.rowHeight = 34
    gridView.displayOptions.fitStyle = 'even'
    gridView.footers.visible = false
    gridView.setStateBar({
      visible: false
    })
    gridView.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
    gridView.displayOptions.selectionStyle = 'rows'
    gridView.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    $vm.cstCatList()

    if ($vm.parentInfo.position === 'bkgShipper') {
      $vm.param.cstCatCd = '01'
    } else if ($vm.parentInfo.position === 'actShipper') {
      $vm.param.cstCatCd = '30'
    } else if ($vm.parentInfo.position === 'hcneShipper') {
      $vm.param.cstCatCd = '01'
    }

    provider2 = new LocalDataProvider(true)
    gridView2 = new GridView('realgrid2')
    provider2.setFields(fields2)
    gridView2.setColumns(columns2)
    gridView2.setDataSource(provider2)
    gridView2.header.heights = [30]
    gridView2.displayOptions.rowHeight = 34
    gridView2.displayOptions.fitStyle = 'even'
    gridView2.footers.visible = false
    gridView2.setStateBar({
      visible: false
    })
    gridView2.setCheckBar({
      visible: true,
      syncHeadCheck: true
    })
    gridView2.displayOptions.selectionStyle = 'rows'
    gridView2.setSortingOptions({
      keepFocusedRow: true,
      style: 'inclusive',
      showSortOrder: true
    })

    $vm.getCountryList()

    if ($vm.parentInfo.ships.length !== 0) {
      $vm.param.ships = $vm.parentInfo.ships.join(',')
      $vm.getCstChooseList($vm.param)
    }
  },
  methods: {
    getCountryList () {
      cpCommonInfo.countryList().then(response => {
        this.countryList = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    getCstChooseList (vals) {
      cpCommonInfo.cstChooseList(vals).then(response => {
        this.cstChooseList = response.data
        provider2.setRows(this.cstChooseList)
      }).catch(err => {
        console.log(err)
      })
    },
    cstCatList () {
      cpCommonInfo.cstCatList().then(response => {
        this.cstCatlist = response.data
      }).catch(err => {
        console.log(err)
      })
    },
    openAlert (msg) {
      let obj = {
        alertType: 'simple',
        onClose: this.closeFun,
        customCloseBtnClass: 'button blue lg simplert-test1',
        customCloseBtnText: this.$t('cp.COMMON.005'),
        useIcon: false,
        customClass: 'simple_alert',
        message: msg,
        type: 'info'
      }
      this.$ekmtcCommon.alert(obj)
    },
    searchData () {
      if (this.param.cstNo === '' && this.param.cstEnm === '' && this.param.cstNm === '' && this.param.ctrNm === '' && this.param.salUno === '') {
        this.openAlert(this.$t('cp.CTRL010P050.011'))
        return false
      }

      cpCommonInfo.cstSearchList(this.param).then(response => {
        this.list = response.data
        // 헤더 체크박스의 체크 잔존 오류 수정
        gridView.checkAll(false)
        if (this.list.length === 0) {
          this.$ekmtcCommon.alertCallback(this.$t('cp.CTRL010P050.012'), () => {
            return false
          })
        }
        provider.setRows(this.list)
      }).catch(err => {
        console.log(err)
      })
    },
    layerClose () {
      this.$emit('close')
      $('.popup_dim').fadeOut()
      $('body,html').removeAttr('style')
      return false
    },
    add () {
      const checkedRows = provider.getJsonRows().filter((_row, idx) => gridView.getCheckedRows().includes(idx))
      const existCstNo = provider2.getJsonRows(0, -1).map((row) => row.cstNo)
      const notExistRows = checkedRows.filter((row) => !existCstNo.includes(row.cstNo))
      provider2.addRows(notExistRows)
    },
    del () {
      provider2.removeRows(gridView2.getCheckedRows())
      gridView2.checkAll(false)
    },
    select () {
      const shipper = provider2.getJsonRows(0, -1).map((row) => row.cstNo)
      const shipLength = shipper.length
      const lastShip = shipper.at(-1)

      this.$emit('close', { shipper: shipper, shipLength: shipLength, lastShip: lastShip })
      this.layerClose()
    }
  }
}
</script>
